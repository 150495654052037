import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Capell convex amb el marge enrotllat que poc a poc s’obre, s’aplana i sovint es deforma. La cutícula presenta petites esquames molt atapeïdes amb coloracions vinoses o porpres i en els vells presenten taques grogoses més marcades al marge. En temps humit presenta viscositat. La superfície del capell arriba a dimensions considerables de 4 a 12 cm. Davall el capell les làmines són decurrents, blanques, separades i amb taques i l’aresta color vi. El peu és curt i gruixut, de 3 a 8 cm x 1 a 3,5 cm, cilíndric, blanquinós tacat de color vinós. Les espores són blanques en massa, el·líptiques, de 6-7 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      